import Column from "../../ui/column"
import Title from "../../ui/title"
import Text from "../../ui/text"
import Layout from "../layout/layout"
import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import DashboardTab from "./dashboard-tab"

const HomePage = () => {
  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <Column>
          <Title>
            Portal do Cliente
          </Title>
          <Text>
            Configuração e monitoramento
          </Text>
        </Column>
        <DashboardTab />
      </Column>
    </Layout>
  )
} 

export default withRedirectUnauthenticated(HomePage)